export const determineLoginIdType = (
    value: string,
    phoneNumberCallback: CallableFunction,
    emailCallback: CallableFunction,
) => {
    const phoneRegex = /^[+\d\s()-]*$/;
    if (value && phoneRegex.test(value)) {
        phoneNumberCallback(value);
    } else {
        emailCallback(value);
    }
};
